import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAuthValue } from "@/contexts/contextAuth";
import useApi from "./useApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import pick from "lodash/pick";
import { useRouter } from "next/router";
import { checkNativePage } from "@/libs/utils";

export enum PlateVinFormType {
  Vin = "Vin",
  Plate = "Plate",
}

// Define the schema
const plateVinEntryFormSchema = z
  .object({
    type: z.nativeEnum(PlateVinFormType),
    plate: z.string().optional(),
    vin: z.string().optional(),
    coBrandedName: z.string().optional(),
    state: z.string().optional(),
  })
  .superRefine((data, ctx) => {
    // If type is Plate, ensure plate is provided
    if (data.type === PlateVinFormType.Plate && !data.plate) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["plate"],
        message: "License plate is required",
      });
    }

    if (
      data.type === PlateVinFormType.Plate &&
      (!data.state || data.state.length < 1)
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["state"],
        message: "State is required for license plate lookup",
      });
    }

    // If type is Vin, ensure vin is provided
    if (data.type === PlateVinFormType.Vin && !data.vin) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["vin"],
        message: "Vin is required",
      });
    }
  });

const defaultValues = {
  state: "",
  plate: "",
  vin: "",
};
export type PlateVinEntryForm = z.infer<typeof plateVinEntryFormSchema>;
export const usePlateVinEntryForm = () => {
  const router = useRouter();
  const queryClient = useQueryClient();
  const isNative = checkNativePage();

  const form = useForm<PlateVinEntryForm>({
    resolver: zodResolver(plateVinEntryFormSchema),
    defaultValues: {
      type: PlateVinFormType.Plate,
      ...defaultValues,
    },
  });
  const coBrandedName = form.watch("coBrandedName");
  const currentType = form.watch("type");
  const currentVin = form.watch("vin");
  const { isSubmitted: isFormSubmitted } = form.formState;
  const isVinShort = useMemo(() => {
    if (isFormSubmitted) {
      return currentVin && currentVin.length < 17;
    }
    return false;
  }, [currentVin, isFormSubmitted]);
  const changeType = useCallback(
    (type: PlateVinFormType) => {
      if (currentType !== type) {
        form.reset({
          type,
          ...defaultValues,
          coBrandedName,
        });
      }
    },
    [form, currentType, coBrandedName]
  );

  const changeState = useCallback(
    (state: string) => {
      form.setValue("state", state);
    },
    [form]
  );

  const { setIsLoading, setMessage, setType, setIsShow } = useAuthValue();
  const { makeRequest } = useApi();
  const onSuccess = useCallback(
    (data: any) => {
      const { id } = data;
      queryClient.setQueryData(["sell-inquiry", String(id)], data);
      if (!isVinShort) {
        const baseUrl = `${isNative ? "/native" : ""}/sell/details`;
        router.push(`${baseUrl}?inquiry=${id}`);
      } else {
        setIsOpenVintageVehicleModal(true);
      }
    },
    [queryClient, router, isVinShort, isNative]
  );
  const onMutationSettled = () => setIsLoading(false);
  const decodeVinMutation = useMutation({
    onSuccess,
    onSettled: onMutationSettled,
    mutationFn: (data: PlateVinEntryForm) =>
      makeRequest({
        method: "POST",
        url: "/api/vehicles/sell-inquiry/vin",
        data: pick(data, ["vin", "state", "coBrandedName"]),
      }).catch((err) => {
        setType("error");
        setMessage(
          "Vehicle not found. Please check you've entered the information correctly."
        );
        setIsOpenVintageVehicleModal(true);
        setIsShow(true);
        throw err;
      }),
  });
  const createVintageInquiryMutation = useMutation({
    onSuccess,
    onSettled: onMutationSettled,
    mutationFn: (data: PlateVinEntryForm) =>
      makeRequest({
        method: "POST",
        url: "/api/vehicles/sell-inquiry/vintage",
        data: pick(data, ["vin", "state", "coBrandedName"]),
      }).catch((err) => {
        setType("error");
        setMessage(
          "Vehicle not found. Please check you've entered the information correctly."
        );
        setIsShow(true);
        throw err;
      }),
  });
  const decodeLicensePlateMutation = useMutation({
    onSuccess,
    onSettled: onMutationSettled,
    mutationFn: (data: PlateVinEntryForm) => {
      return makeRequest({
        method: "POST",
        url: "/api/vehicles/sell-inquiry/plate",
        data: pick(data, ["plate", "state", "coBrandedName"]),
      }).catch((err) => {
        setType("error");
        setMessage(
          "Vehicle not found. Please check you've entered the information correctly."
        );
        setIsShow(true);
        throw err;
      });
    },
  });

  const handleSubmit = form.handleSubmit(
    (data) => {
      setIsLoading(true);
      if (data.type === PlateVinFormType.Plate) {
        decodeLicensePlateMutation.mutate(data);
      } else {
        if (isVinShort) {
          createVintageInquiryMutation.mutate(data);
        } else {
          decodeVinMutation.mutate(data);
        }
      }
    },
    () => {
      setType("error");
      setMessage("Missing fields, please fill out all required fields.");
      setIsShow(true);
    }
  );
  const handleCreateVintage = form.handleSubmit(
    (data) => {
      setIsLoading(true);
      createVintageInquiryMutation.mutate(data);
    },
    () => {
      setType("error");
      setMessage("Missing fields, please fill out all required fields.");
      setIsShow(true);
    }
  );

  const [openVintageVehicleModal, setIsOpenVintageVehicleModal] =
    useState<Boolean>(false);

  const [isVehicleModalOnSellEntry, setIsVehicleModalOnSellEntry] =
    useState<Boolean>(false);
  const [isStateModalOpen, setIsStateModalOpen] = useState<Boolean>(false);

  const showVehicleModal = useCallback(() => {
    setIsVehicleModalOnSellEntry(!isVehicleModalOnSellEntry);
  }, [isVehicleModalOnSellEntry]);

  const showStateModal = useCallback(
    () => setIsStateModalOpen(!isStateModalOpen),
    [isStateModalOpen]
  );

  const isBadReq =
    decodeLicensePlateMutation.isError || decodeVinMutation.isError;
  return {
    changeType,
    form,
    changeState,
    isVinShort,
    handleSubmit,
    isBadReq,
    showVehicleModal,
    isVehicleModalOnSellEntry,
    setIsVehicleModalOnSellEntry,
    showStateModal,
    isStateModalOpen,
    handleCreateVintage,
    openVintageVehicleModal,
    setIsOpenVintageVehicleModal,
  };
};
