import { useState, useRef, useEffect } from "react";
import Image from "next/image";
import Link from "next/link";
import StateListModal from "@/components/modals/StateListModal";
import { checkNativePage, parseBgColor } from "@/libs/utils";
import cn from "classnames";
import {
  PlateVinFormType,
  usePlateVinEntryForm,
} from "@/hooks/usePlateVinEntryForm";
import { useRouter } from "next/router";
import { useAuthValue } from "@/contexts/contextAuth";
import VintageEntryModal from "./VintageEntryModal";

type Props = {
  data: any;
};

const Design2VinEntry = ({ data }: Props) => {
  const btnRef = useRef<HTMLButtonElement>(null);
  const btnRef3 = useRef<HTMLButtonElement>(null);
  const isNative = checkNativePage();
  const {
    form,
    changeType,
    changeState,
    isVinShort,
    handleSubmit,
    isBadReq,
    isStateModalOpen,
    showStateModal,
    openVintageVehicleModal,
    setIsOpenVintageVehicleModal,
  } = usePlateVinEntryForm();
  const {
    register,
    formState: { errors },
    watch,
  } = form;
  const selectedType = watch("type");
  const selectedState = watch("state");
  const currentVin = watch("vin");
  const isVin = selectedType === PlateVinFormType.Vin;

  const { setIsLoading } = useAuthValue();

  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    if (data.CoBrandedName) {
      form.setValue("coBrandedName", data.CoBrandedName);
    }
  }, [data.CoBrandedName]);
  const router = useRouter();

  useEffect(() => {
    if (router.isReady) {
      const { vin } = router.query;
      const vinValue = Array.isArray(vin) ? vin[0] : vin;

      if (vinValue) {
        setIsLoading(true);
        form.setValue("vin", vinValue);
        changeType(PlateVinFormType.Vin);
      }
    }
  }, [router.isReady, router.query, form, changeType]);
  const isSellPartnerPage = Boolean(data.CoBrandedName);

  const vinForm = (
    <div
      className={cn(
        `${parseBgColor(
          data.Background
        )} border border-[#C3C3C3] px-8 my-8 rounded-lg`,
        {
          "m-0 p-0 mt-0 mb-0 py-6 md:py-16 w-full md:w-[400px]":
            isSellPartnerPage,
          "xs:pl-[65px] xs:pr-[75px] xs:w-[434px] w-[90%] mx-auto pt-[70px] pb-[80px]":
            !isSellPartnerPage,
        }
      )}
    >
      <div className="w-full flex text-sm bg-[#00B3DE26] mb-7 rounded-full relative">
        <div
          className="absolute top-1 bottom-1 w-1/2 bg-white rounded-full transition-all duration-300"
          style={{ left: isVin ? "50%" : "4px" }}
        />
        <div
          className={`w-1/2 text-center py-2 z-10 cursor-pointer transition-colors duration-300 text-black`}
          onClick={() => {
            changeType(PlateVinFormType.Plate);
          }}
        >
          License plate
        </div>
        <div
          className={`w-1/2 text-center py-2 z-10 cursor-pointer transition-colors duration-300 text-black`}
          onClick={() => {
            changeType(PlateVinFormType.Vin);
          }}
        >
          VIN
        </div>
      </div>

      {isVin ? (
        <div className="w-full">
          <div className="flex justify-center mt-4">
            <div className="relative w-full z-0">
              <input
                type="text"
                className={`form-control block w-full px-3 py-[6px] text-base font-medium bg-white border border-solid ${
                  isBadReq || Boolean(errors.vin) || isVinShort
                    ? "border-[#d80808] focus:border-[#d80808]"
                    : "border-[#9797aa] focus:outline focus:outline-[#cbced4] focus:border-black"
                } rounded m-0 focus:outline-4 transition-all`}
                placeholder="Enter VIN..."
                {...register("vin")}
              />
              {(isVinShort || isBadReq || Boolean(errors.vin)) && (
                <Image
                  width={12}
                  height={12}
                  className="absolute top-[11px] right-[10px] w-[20px] z-40"
                  src="/assets/invalid.svg"
                  alt="invalid"
                />
              )}
            </div>
          </div>

          <div className="w-full flex justify-center">
            <button
              className="w-full bg-[#00B3DE] hover:opacity-80 border border-[#00A0C7] text-white text-base font-bold py-2 px-4 rounded cursor-pointer mt-4"
              onClick={handleSubmit}
            >
              Get started
            </button>
          </div>
        </div>
      ) : (
        <div className="w-full">
          <div className="flex justify-center mt-4 gap-4">
            <div className="relative w-2/3">
              <input
                type="text"
                className={`form-control block w-full px-3 py-[6px] text-base font-medium bg-white border border-solid ${
                  isBadReq || Boolean(errors.plate)
                    ? "border-[#d80808] focus:border-[#d80808]"
                    : "border-[#9797aa] focus:outline focus:outline-[#cbced4] focus:border-black"
                } rounded m-0 focus:outline-4 transition-all`}
                placeholder="Enter License plate"
                {...register("plate")}
              />
            </div>
            <div className="w-1/3 relative">
              <button
                className={`form-control block w-full bg-white flex items-center justify-between text-base text-white font-normal py-[6px] px-3 border focus:border-2 ${
                  Boolean(errors.state)
                    ? "border-[#d80808]"
                    : "border-[#9797aa]"
                } border-solid focus:outline focus:border-[#00a0c7] focus:outline-[#bec3cd] focus:outline-4 transition-all rounded`}
                onClick={() => showStateModal()}
                ref={btnRef3}
              >
                <span className="text-base text-[#212529] font-medium truncate">
                  {selectedState == "" ? "" : selectedState}
                </span>
                <Image
                  width={10}
                  height={6}
                  src="/assets/expand_icon.svg"
                  alt="null"
                />
              </button>
              {isStateModalOpen && (
                <StateListModal
                  isStateModalOpened={isStateModalOpen}
                  setIsStateModalOpened={showStateModal}
                  setState={changeState}
                  btnRef={btnRef3}
                />
              )}
            </div>
          </div>
          <div className="w-full flex justify-center">
            <button
              className="w-full bg-[#00B3DE] hover:opacity-80 border border-[#00A0C7] text-white text-base font-bold py-2 px-4 rounded cursor-pointer mt-4"
              onClick={handleSubmit}
            >
              Get started
            </button>
          </div>
        </div>
      )}

      {openVintageVehicleModal && (
        <VintageEntryModal
          openVintageVehicleModal={openVintageVehicleModal}
          setOpenVintageVehicleModal={setIsOpenVintageVehicleModal}
          btnRef={btnRef}
          vin={currentVin ?? ""}
          state={selectedState}
        />
      )}
    </div>
  );

  if (isSellPartnerPage) {
    return (
      <div className="flex flex-col md:flex-row justify-center gap-4 px-4 items-center my-8 md:gap-[70px]">
        <div className="flex flex-col px-4 items-center gap-6 md:items-start md:w-[440px]">
          <div
            className={`${parseBgColor(
              data.Background
            )} flex items-center gap-1`}
          >
            {!isMobileView && (
              <div className="text-[#7C7C7C] text-[12px] font-[400]">
                Referred by
              </div>
            )}
            {isMobileView && (
              <div className="text-[#7C7C7C] text-[12px] font-[400] mb-2">
                Referred by
              </div>
            )}

            <div className="w-full max-w-[152px]">
              <Image
                className="w-full h-auto object-contain"
                src={data?.CoBrandedImage?.data.attributes.url}
                alt={`${data.CoBrandedName} logo`}
                width={600}
                height={200}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2 w-[284px] md:w-full">
            <h1 className="text-black font-bold text-[34px] leading-[35px] md:text-[50px] md:leading-[57px]">
              Sell privately, get more for your car!
            </h1>
            <p className="text-[#3E3E3E] md:text-2xl">
              The only end-to-end transactional marketplace for private party
              vehicles.
            </p>
          </div>
        </div>
        {vinForm}
      </div>
    );
  }

  return (
    <>
      <div className="text-center font-600 text-[36px] my-4 mx-4">
        {data.Title}
      </div>
      {vinForm}
    </>
  );
};

export default Design2VinEntry;
