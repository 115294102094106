import { useRef } from "react";
import Image from "next/image";
import JumpLinkTarget from "@/components/JumpLinkTarget";
import IconBar from "@/components/IconBar";
import ContentBlock from "@/components/ContentBlock";
import FaqCollection from "@/components/FaqCollection";
import InlineImage from "@/components/InlineImage";
import VehicleSearch from "@/components/VehicleSearch";
import BulletedList from "@/components/BulletedList";
import Lists from "@/components/Lists";
import JumpLinks from "@/components/JumpLinks";
import ContactForm from "@/components/ContactForm";
import PaymentCalculator from "@/components/PaymentCalculator";
import SalesTaxCalc from "@/components/SalesTaxCalc";
import SellEntry from "@/components/SellEntry";
import VideoPlayer from "@/components/VideoPlayer";
import WatermarkBlock from "@/components/WatermarkBlock";
import HeaderWithOverlap from "@/components/HeaderWithOverlap";
import LinkHub from "@/components/LinkHub";
import Disclaimer from "@/components/Disclaimer";
import HowItWorks from "@/components/HowItWorks";
import JumpButton from "@/components/JumpButton";
import ListTab from "@/components/ListTab";
import ColumnsStart from "@/components/ColumnsStart";
import ColumnsEnd from "@/components/ColumnsEnd";
import Table from "@/components/Table";
import HtmlSection from "@/components/HtmlSection";
import TestimonialSection from "@/components/Testimonial";
import BrowseUsedCarsSection from "@/components/dealnow/BrowseUsedCarsSection";
import {
  parseContent,
  checkHomePage,
  checkBlogPage,
  checkHowItWorksPage,
  checkDealNowPage,
  checkSellPage,
  checkIfPricingPage,
  checkRunbuggyTestPage,
  parseBgColor,
  checkNewSellPages,
} from "@/libs/utils";
import RunBuggy from "@/components/RunBuggy";
import DealNowSection from "@/components/dealnow/DealNowSection";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { BlogSearch } from "./blog/BlogSearch";
import { googleRecaptchaApiKey } from "@/libs/clientConfig";
import InformationalBlock from "./sell/InformationalBlock";
import Scripts from "./Scripts";
import { AuthorBio } from "./authors/AuthorBio";
import BuyCarInformational from "./buy/BuyVehicleInformation";
import { MiniAuthorBio } from "./authors/MiniAuthorBio";
import { AuthorBlogs } from "./authors/AuthorBlogs";
import { SellCustomerPartnerPage } from "./sell/SellCustomerPartnerPage";

type Props = {
  data: any;
};

const SlugMainComp = ({ data }: Props) => {
  const runbuggyRef = useRef(null);
  let videoSection: any = null;

  const scrollToRunBuggy = () => {
    if (!!runbuggyRef.current) {
      // @ts-ignore
      runbuggyRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  if (Array.isArray(data?.attributes?.Content)) {
    for (let item of data?.attributes?.Content) {
      if (item.Title == "Watch the first PrivateAuto transaction, live!") {
        videoSection = item;
      }
    }
  }
  let authorInfo: any = null;

  if (data?.attributes?.author?.data?.attributes?.AuthorName) {
    authorInfo = {
      name: data?.attributes?.author?.data?.attributes?.AuthorName,
      headshotUrl:
        data?.attributes?.author?.data?.attributes?.Headshot?.data?.attributes
          ?.url,
      slug: data?.attributes?.author?.data?.attributes?.slug,
      createdAt: data?.attributes?.createdAt,
      updatedAt: data?.attributes?.updatedAt,
      AuthorBio: data?.attributes?.author?.data?.attributes?.AuthorBio,
    };
  }

  return (
    <main className="w-full">
      <section className="w-4/6 mx-auto">
        {!checkBlogPage() &&
        data &&
        data.attributes?.Image?.data?.attributes?.formats ? (
          <div className="w-full flex justify-center mt-8">
            <Image
              src={data.attributes.Image.data.attributes.url}
              width={data.attributes.Image.data.attributes.formats?.small.width}
              height={
                data.attributes.Image.data.attributes.formats?.small.height
              }
              sizes={`(min-width: ${data.Width * 0.06 + data.Width}px) ${
                data.Width
              }px, calc(94vw + 18px)`}
              alt={
                data.attributes.Image.data.attributes.formats?.small.name || ""
              }
            />
          </div>
        ) : (
          ""
        )}
        {data?.attributes?.Body ? (
          <div
            className="text-base text-left break-words py-6"
            dangerouslySetInnerHTML={{
              __html: parseContent(data?.attributes?.Body),
            }}
          ></div>
        ) : (
          ""
        )}
      </section>
      <div className="w-full">
        {Array.isArray(data?.attributes?.Content) &&
        data.attributes.Content.length != 0
          ? data.attributes.Content.map((item: any, index: number) => {
              const next_lists_item =
                index != data.attributes.Content.length - 1
                  ? data.attributes.Content[index + 1]
                  : { __component: "" };

              const thirdListsItem =
                index != data.attributes.Content.length - 2 ||
                data.attributes.Content.length - 1
                  ? data.attributes.Content[index + 2]
                  : { __component: "" };

              const before_lists_item =
                index != 0
                  ? data.attributes.Content[index - 1]
                  : { __component: "" };

              return item.__component == "page-elements.jump-link-target" ? (
                <JumpLinkTarget key={index} data={item} />
              ) : item.__component == "app-forms.custom-partner-page" ? (
                <SellCustomerPartnerPage key={index} data={item} />
              ) : item.__component == "page-elements.icon-bar" ? (
                !checkSellPage() && (
                  <IconBar
                    key={index}
                    data={item}
                    isBelongedToColumn={
                      data.attributes.Content[index - 1]?.__component ===
                      "page-elements.column"
                    }
                  />
                )
              ) : item.__component == "page-elements.blog-search" ? (
                <BlogSearch data={item} key={index} />
              ) : item.__component == "page-elements.how-it-works" ? (
                <HowItWorks key={index} />
              ) : item.__component == "page-elements.buy-car-informational" ? (
                <BuyCarInformational key={index} />
              ) : item.__component == "page-elements.informational-block" ? (
                <InformationalBlock key={index} />
              ) : item.__component == "page-elements.image-text" ? (
                !checkHowItWorksPage() &&
                item.Title !=
                  "Watch the first PrivateAuto transaction, live!" && (
                  <ContentBlock
                    key={index}
                    data={item}
                    authorInfo={authorInfo}
                    allData={data.attributes.Content}
                    scrollToRunBuggy={scrollToRunBuggy}
                    index={index}
                  />
                )
              ) : item.__component == "page-elements.inline-image" ? (
                <InlineImage key={index} data={item} />
              ) : item.__component == "page-elements.bulleted-list" ? (
                <BulletedList key={index} data={item} />
              ) : item.__component == "app-forms.vehicle-search" ? (
                !checkHomePage() && <VehicleSearch key={index} data={item} />
              ) : item.__component == "app-forms.contact-form" ? (
                <GoogleReCaptchaProvider
                  key={`recaptcha-${index}`}
                  reCaptchaKey={googleRecaptchaApiKey}
                >
                  <ContactForm key={index} data={item} />
                </GoogleReCaptchaProvider>
              ) : item.__component == "app-forms.run-buggy" ? (
                <RunBuggy key={index} data={item} runbuggyRef={runbuggyRef} />
              ) : item.__component == "app-forms.payment-calculator" ? (
                <PaymentCalculator key={index} data={item} />
              ) : item.__component == "app-forms.tax-calculator" ? (
                <SalesTaxCalc key={index} data={item} />
              ) : item.__component == "app-forms.vin-entry" ? (
                !checkSellPage() && <SellEntry key={index} data={item} />
              ) : item.__component == "page-elements.video-player" ? (
                !checkSellPage() && <VideoPlayer key={index} data={item} />
              ) : item.__component == "page-elements.watermark-block" ? (
                !checkHowItWorksPage() && (
                  <WatermarkBlock key={index} data={item} />
                )
              ) : item.__component == "page-elements.author-blogs" ? (
                <AuthorBlogs key={index} data={item} />
              ) : item.__component == "page-elements.author-bio" ? (
                <AuthorBio key={index} data={item} />
              ) : item.__component == "page-elements.header-with-overlap" ? (
                !checkSellPage() && (
                  <HeaderWithOverlap key={index} data={item} />
                )
              ) : item.__component == "page-elements.list" &&
                before_lists_item.__component != "page-elements.list" &&
                next_lists_item.__component == "page-elements.list" &&
                thirdListsItem?.__component != "page-elements.list" ? (
                <div key={index}>
                  <div
                    className={`relative w-full ${
                      item.BackgroundStyle === "Full"
                        ? parseBgColor(item.Background)
                        : parseBgColor(item.BackgroundLower)
                    } py-8 z-10`}
                  >
                    {item.BackgroundStyle === "Half" && (
                      <div
                        className={`absolute lef-0 top-0 w-full h-[430px] ${parseBgColor(
                          item.Background
                        )} z-[-10]`}
                      ></div>
                    )}
                    <h2
                      className={`text-center ${
                        checkIfPricingPage() ||
                        checkNewSellPages() ||
                        item.Background?.toLowerCase() === "dark blue"
                          ? "text-[#FFFFFF]"
                          : ""
                      } text-[3.5rem] font-bold ${
                        !checkIfPricingPage() && "py-8"
                      }`}
                    >
                      Pricing
                    </h2>
                    {checkIfPricingPage() && (
                      <p className="text-2xl text-[#FFFFFF] text-center pb-12">
                        The simple and secure way to buy/sell your car
                        privately.
                      </p>
                    )}
                    <div className="block sm:flex flex flex-wrap justify-center items-end space-x-0 sm:space-x-20">
                      <Lists data={item} />
                      <Lists data={next_lists_item} />
                    </div>
                    <Disclaimer data={item} />
                  </div>
                  {checkHomePage() && (
                    <ContentBlock
                      key={index}
                      data={videoSection}
                      allData={data.attributes.Content}
                      authorInfo={authorInfo}
                      scrollToRunBuggy={scrollToRunBuggy}
                      index={index}
                    />
                  )}
                </div>
              ) : item.__component === "page-elements.testimonial" ? (
                <TestimonialSection key={index} data={item} />
              ) : item.__component == "page-elements.list" &&
                before_lists_item.__component == "page-elements.list" ? (
                ""
              ) : item.__component == "page-elements.list" &&
                before_lists_item.__component != "page-elements.list" &&
                next_lists_item.__component != "page-elements.list" ? (
                <Lists key={index} data={item} />
              ) : item.__component == "page-elements.list" &&
                before_lists_item.__component != "page-elements.list" &&
                next_lists_item.__component == "page-elements.list" &&
                thirdListsItem.__component == "page-elements.list" ? (
                <>
                  <div className="block sm:flex flex flex-wrap justify-center items-end space-x-0 sm:space-x-5">
                    <Lists key={index} data={item} notPricingPage={true} />
                    <Lists
                      key={index}
                      data={next_lists_item}
                      notPricingPage={true}
                    />
                    <Lists
                      key={index}
                      data={thirdListsItem}
                      notPricingPage={true}
                    />
                  </div>
                </>
              ) : item.__component == "page-elements.jump-links" ? (
                <JumpLinks key={index} data={item} />
              ) : item.__component == "page-elements.jump-button" ? (
                <JumpButton key={index} data={item} />
              ) : item.__component == "page-elements.link-hub" ? (
                <LinkHub key={index} data={item} />
              ) : item.__component == "page-elements.tab" ? (
                !checkHowItWorksPage() && <ListTab key={index} data={item} />
              ) : item.__component == "page-elements.faq-collection" ? (
                checkDealNowPage() ? (
                  <div key={index}>
                    <BrowseUsedCarsSection />
                    <FaqCollection key={index} data={item} />
                  </div>
                ) : (
                  <FaqCollection key={index} data={item} />
                )
              ) : item.__component == "page-elements.columns-start" ? (
                <ColumnsStart key={index} data={data} index={index} />
              ) : item.__component == "page-elements.columns-end" ? (
                <ColumnsEnd
                  key={index}
                  data={item}
                  previousData={data.attributes.Content[index - 1]}
                />
              ) : item.__component == "page-elements.table" ? (
                <Table key={index} data={item} />
              ) : item.__component == "page-elements.html" ? (
                <HtmlSection key={index} data={item} />
              ) : item.__component == "page-elements.scripts" ? (
                <Scripts key={index} data={item} />
              ) : item.__component == "page-elements.deal-now" ? (
                checkRunbuggyTestPage() && (
                  <DealNowSection key={index} data={item} />
                )
              ) : (
                ""
              );
            })
          : null}
        {authorInfo && <MiniAuthorBio data={authorInfo} />}
      </div>
    </main>
  );
};

export default SlugMainComp;
