import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useAuthValue } from "@/contexts/contextAuth";
import useApi from "./useApi";
import { useMutation } from "@tanstack/react-query";
import { useQuerySellInquiry } from "./useQuerySellInquiry";
import { checkNativePage, isWebView } from "@/libs/utils";
import pick from "lodash/pick";
import { useCallback, useEffect, useState } from "react";
import { useRouter } from "next/router";

const vintageFormSchema = z.object({
  year: z.number().int().min(1900).max(2099),
  make: z.string().min(1, "Make is required"),
  model: z.string().min(1, "Model is required"),
  vin: z.string().min(1, "Vin is not valid"),
});

export interface ModelOptions {
  uvc: string;
  name: string;
}

export type VintageForm = z.infer<typeof vintageFormSchema>;
export const useVintageForm = () => {
  const { data: inquiryData } = useQuerySellInquiry();
  const [showModelInput, setShowModelInput] = useState(false);
  const [modelOptions, setModelOptions] = useState<ModelOptions[]>([]);
  const isNative = checkNativePage();
  const router = useRouter();
  const form = useForm<VintageForm>({
    resolver: zodResolver(vintageFormSchema),
    defaultValues: {},
  });

  const { reset, register, getValues } = form;
  useEffect(() => {
    if (inquiryData?.vintageVehicle) {
      reset({ ...inquiryData.vintageVehicle });
    }
  }, [inquiryData, reset]);

  const { makeRequest } = useApi();
  const { setIsLoading, setIsShow, setType, setMessage, setSource } =
    useAuthValue();

  const onSuccess = useCallback((response: any) => {
    const { year, make, model } = response.vintageVehicle;
    const inquiryId = response.id;
    const sellTag = {
      event: "Sell Your Vehicle",
      Login: "pre",
      vehicleType: "Auto",
      year,
      make,
      model,
    };

    window?.dataLayer && window.dataLayer.push(sellTag);
    window?.fbq && window.fbq("track", "Sell Your Vehicle");
    setSource("SELLER");
    const baseUrl = `${isNative ? "/native" : ""}/sell/details`;
    router.push(`${baseUrl}?inquiry=${inquiryId}`);
  }, []);

  const onMutationSettled = () => setIsLoading(false);
  const createVintageInquiryMutation = useMutation({
    onSuccess,
    onSettled: onMutationSettled,
    mutationFn: (input: VintageForm) => {
      const modelData = modelOptions.find(
        (model) => input.model === model.name
      );
      debugger;
      return makeRequest({
        method: "POST",
        url: "/api/vehicles/sell-inquiry/uvc",
        data: {
          uvc: modelData?.uvc,
          vintageVehicle: input,
        },
      }).catch((err) => {
        setType("error");
        setMessage("Something went wrong");
        setIsShow(true);
        throw err;
      });
    },
  });

  const handleSubmit = form.handleSubmit((data: VintageForm) => {
    setIsLoading(true);
    createVintageInquiryMutation.mutate(data);
  });

  const handleMakeBlur = () => {
    const { year, make } = getValues();
    if (year && make) {
      makeRequest({
        method: "POST",
        url: `/api/vehicles/sell-inquiry/drilldown`,
        data: { year, make },
      })
        .then((response) => {
          console.log(response);
          setShowModelInput(true);
          setModelOptions(response?.models);
        })
        .catch((err) => {
          console.error("Error fetching vintage models:", err);
          throw err;
        });
    }
  };

  return {
    form,
    handleSubmit,
    inquiryData,
    showModelInput,
    setShowModelInput,
    modelOptions,
    register: {
      ...register("make", { onBlur: handleMakeBlur }),
      ...register("year"),
      ...register("model"),
      ...register("vin"),
    },
  };
};
