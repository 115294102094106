import NextImage from "next/image";

export const SellCustomerPartnerPage = (props: any) => {
  const privateAutoBenefits = [
    {
      imgUrl: "/assets/partners/avoid-fraud.svg",
      title: "Avoid fraud",
      description:
        "Our app uses smart fraud prevention to verify buyers, confirm available funds, and secure transactions—keeping you safe from scams.",
    },
    {
      imgUrl: "/assets/partners/deal.svg",
      title: "Easily do the deal",
      description:
        "Everything you need—agree on price, add services, manage loan payoff, e-sign documents, and transfer funds seamlessly.",
    },
    {
      imgUrl: "/assets/partners/transfer.svg",
      title: "Instant transfers",
      description:
        "The buyer can send any amount to purchase your vehicle securely, instantly anytime anywhere — with no money limit! ",
    },
  ];
  const listingBenefits = [
    "Buyer & seller ID verification",
    "Verified buyers funds",
    "E-sign bill of sale",
    "Instant money transfer P2P",
    "Secure chat",
  ];
  return (
    <div>
      <div
        style={{
          background:
            "linear-gradient(180deg, #5E58A9 0%, #2D7795 50%, #2F6597 100%",
        }}
        className="w-full py-6 md:py-[60px] flex justify-center"
      >
        <div className="w-[300px] md:w-full">
          <p className="text-xl leading-[26px] md:text-[30px] md:leading-[43px] font-bold text-center px-4">
            <span className="text-white">
              The only private-party marketplace with built in
            </span>
            <br />
            <span className="text-[#83FFE3]">
              fraud prevention and payment technology
            </span>
          </p>
        </div>
      </div>
      <div className="w-full py-6 md:py-[60px] flex justify-center px-8">
        <div className="flex flex-col gap-6 w-full items-center">
          <h1 className="text-center text-[#3E3E3E] font-bold text-2xl md:text-[40px] leading-[29px] md:leading-[65px]">
            Why use PrivateAuto
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-[70px]">
            {privateAutoBenefits.map((benefit) => (
              <div
                key={benefit.title}
                className="flex flex-col gap-4 md:gap-6 items-center max-w-[290px]"
              >
                <div className="w-[281px] h-[333px]">
                  <NextImage
                    src={benefit.imgUrl}
                    alt={benefit.title}
                    width={281}
                    height={333}
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <h2 className="text-[#2E3B54] font-bold text-[28px]">
                    {benefit.title}
                  </h2>
                  <p className="text-[#2E3B54] font-normal">
                    {benefit.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="w-full py-6 md:py-[60px] flex justify-center px-8 bg-[#3B4965]">
        <div className="flex flex-col gap-10">
          <div className="text-center flex flex-col gap-2 text-white">
            <h1 className="text-2xl md:text-3xl font-bold">Pricing</h1>
            <p className="font-medium md:text-xl">
              The safe and simple way to close the sale on your own.
            </p>
          </div>
          <div className="rounded-[20px] border border-[#CFCFCF] bg-white flex flex-col md:flex-row gap-5">
            <div className="px-8 py-5 md:py-8">
              <div className="text-[#090914] flex flex-col gap-5">
                <p className="leading-[18px] text-center font-semibold">
                  Listing fee
                </p>
                <div className="rounded-[15px] border border-[#E2E8F0] p-4">
                  <p className="text-[52px] text-center font-semibold">$25</p>
                  <p className="text-sm text-[#2E3B54] font-normal">
                    + $150 closing fee if your car sells
                  </p>
                </div>
              </div>
            </div>
            <div className="hidden md:block h-full w-[1px] border border-l-[.5px] border-[#E1E1E1]"></div>
            <div className="px-8 py-5 md:py-8">
              <ul className="list-none pl-6 space-y-4">
                {listingBenefits.map((e, index) => (
                  <li
                    className="relative before:absolute before:left-[-1.5rem] before:top-[0.1rem] before:w-[18px] before:h-[19px] before:bg-[url('/assets/check-green.svg')] before:bg-contain before:bg-no-repeat"
                    key={index}
                  >
                    {e}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="flex justify-center">
            <p className="text-white text-[10px] font-normal text-center max-w-[505px]">
              *Disclaimer: Account services are provided by US Alliance
              Financial, a licensed and insured banking institution. PrivateAuto
              does not facilitate or handle any funds throughout the
              transaction. All funds in custody of US Alliance. Buyer financing
              may require additional fees.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
