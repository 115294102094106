import { useEffect, useRef, useState } from "react";
import useOutsideClick from "@/hooks/useOutsideClick";
import { useRouter } from "next/router";
import { checkNativePage, isWebView } from "@/libs/utils";
import { useVintageForm } from "@/hooks/useVintageForm";
import { useAuthValue } from "@/contexts/contextAuth";
import { Tooltip } from "react-tooltip";
import Modal, { ModalConfig } from "../modals/Modal";

type Props = {
  openVintageVehicleModal: Boolean;
  setOpenVintageVehicleModal: Function;
  btnRef: any;
  vin: string;
  state?: string;
};

const VintageEntryModal = ({
  openVintageVehicleModal,
  setOpenVintageVehicleModal,
  btnRef,
  vin = "",
  state = "",
}: Props) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const router = useRouter();

  const isNative = checkNativePage();
  const [isMobile, setIsMobile] = useState(false);
  const [enterDetails, setEnterDetails] = useState(false);

  const {
    form,
    handleSubmit,
    inquiryData,
    showModelInput,
    setShowModelInput,
    modelOptions,
  } = useVintageForm();
  const {
    register,
    setValue,
    formState: { errors },
  } = form;
  const { setIsCreateListing } = useAuthValue();

  useEffect(() => {
    setIsCreateListing(true);
    setIsMobile(isWebView());
    form.setValue("vin", vin);

    return () => {
      setIsCreateListing(false);
    };
  }, []);

  useOutsideClick(
    modalRef,
    openVintageVehicleModal,
    setOpenVintageVehicleModal,
    btnRef
  );

  const modalConfig: ModalConfig = {
    showClosingX: true,
    onClose: () => {
      setOpenVintageVehicleModal(false);
    },
  };

  const options = [
    {
      title: "Check for typos",
      subtext: "Look for spaces or any wrong characters in your plate or VIN",
      image: "/assets/xyzIcon.svg",
      action: () => {
        setOpenVintageVehicleModal(false);
      },
    },
    {
      title: "CPI or vintage",
      subtext: "My car is older than 1981, a collectible, or an exotic car",
      image: "/assets/buggyIcon.svg",
      action: () => {
        setEnterDetails(true);
      },
    },
  ];

  return (
    <Modal modalRef={modalRef} modalConfig={modalConfig}>
      {!enterDetails && (
        <div className="w-full ">
          <h1 className="font-bold text-[40px]">Vehicle not found</h1>
          <div className="pb-2 text-base font-normal leading-5">
            You might have mistyped your plate or VIN. If not, are you listing a
            Car of Particular Interest (CPI) or vintage vehicle?
          </div>

          <div className="flex flex-wrap justify-center">
            {options.map((option, index) => (
              <div
                key={index}
                className="w-36 h-40 m-2 cursor-pointer rounded border border-gray-500 hover:border-[#00B3DE] text-center"
                onClick={option.action}
              >
                <div className="m-2 text-center">
                  <div className="h-16 flex justify-center">
                    <img alt="option" src={option.image} className="m-2 mt-3" />
                  </div>
                  <div className="pb-2 mt-1 text-sm font-bold leading-4">
                    {option.title}
                  </div>
                  <div className="text-xs font-normal leading-4">
                    {option.subtext}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {enterDetails && (
        <div className="w-full px-8 space-y-4">
          <button
            onClick={() => {
              setEnterDetails(false);
            }}
            className="flex items-center mt-[-24px] sm:mt-[-36px] text-black text-[16px] font-medium leading-[20px]"
          >
            <img src="assets/arrow-left.svg" alt="back arrow" />
            <span className="ml-1">Back</span>
          </button>{" "}
          <div className="font-bold">Fill out your vehicle Info</div>
          <div className="w-full">
            <p>Year</p>
            <input
              type="number"
              className="form-control w-full px-4 py-1.5 text-base font-medium text-[#495057] bg-white bg-clip-padding border border-solid border-[#ced4da] rounded m-0 focus:border-[#a3b0ca] focus:text-gray-700 focus:bg-white focus:outline focus:outline-[#cbced4] focus:outline-4 transition-all"
              {...register("year", { valueAsNumber: true })}
            />
            {errors.year && (
              <div className="text-sm text-left text-[#ed0a0a] pt-2">
                {errors.year.message}
              </div>
            )}
          </div>
          <div className="w-full">
            <p>Make</p>
            <input
              type="text"
              className="form-control w-full px-4 py-1.5 text-base font-medium text-[#495057] bg-white bg-clip-padding border border-solid border-[#ced4da] rounded m-0 focus:border-[#a3b0ca] focus:text-gray-700 focus:bg-white focus:outline focus:outline-[#cbced4] focus:outline-4 transition-all"
              {...register("make")}
            />
            {errors.make && (
              <div className="text-sm text-left text-[#ed0a0a] pt-2">
                {errors.make.message}
              </div>
            )}
          </div>
          {showModelInput && (
            <div className="w-full">
              <p>Model</p>
              {modelOptions.length > 1 ? (
                <select
                  className="form-control w-full px-4 py-1.5 text-base font-medium text-gray-700 bg-white bg-clip-padding border border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-300 focus:outline-none"
                  {...register("model")}
                >
                  {modelOptions.map((option, index) => (
                    <option key={index} value={option.name}>
                      {option.name}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  type="text"
                  className="form-control w-full px-4 py-1.5 text-base font-medium text-[#495057] bg-white bg-clip-padding border border-solid border-[#ced4da] rounded m-0 focus:border-[#a3b0ca] focus:text-gray-700 focus:bg-white focus:outline focus:outline-[#cbced4] focus:outline-4 transition-all"
                  {...register("model")}
                />
              )}
              {errors.model && (
                <div className="text-sm text-left text-[#ed0a0a] pt-2">
                  {errors.model.message}
                </div>
              )}
            </div>
          )}
          <div className="w-full">
            <p>VIN</p>
            <input
              type="text"
              className="form-control w-full px-4 py-1.5 text-base font-medium text-[#495057] bg-white bg-clip-padding border border-solid border-[#ced4da] rounded m-0 focus:border-[#a3b0ca] focus:text-gray-700 focus:bg-white focus:outline focus:outline-[#cbced4] focus:outline-4 transition-all"
              {...register("vin")}
            />
            {errors.vin && (
              <div className="text-sm text-left text-[#ed0a0a] pt-2">
                {errors.vin.message}
              </div>
            )}
          </div>
          <button
            className="w-full bg-[#00b3de] text-white text-base font-medium py-2 px-4 rounded-lg cursor-pointer"
            onClick={handleSubmit}
          >
            Continue
          </button>
        </div>
      )}

      <Tooltip
        id="info"
        place="top"
        className="text-center"
        style={{ maxWidth: "200px" }}
      />
    </Modal>
  );
};

export default VintageEntryModal;
